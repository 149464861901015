@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/Nunito-Bold.ttf");
}
@font-face {
  font-family: "AmsiPro";
  src: url("./assets/AmsiPro-Black.ttf");
}

.radientText {
  background-image: linear-gradient(60deg, #FF0000, #FF0000, #FF0000, #FF7A00);
  background-clip: text;
  color: transparent;
}

.borderText {
  color: black;
  background: white;
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 5px transparent;
}

.bgcolor4 .ant-modal-content{
  background-color: #D7FFF5;
  border-width: 5px;
  border-color: white;
}
.bgcolor3 .ant-modal-content{
  background-color: #FFBABA;
  border-width: 5px;
  border-color: white;
}
.bgcolor2 .ant-modal-content{
  background-color: #D1FF70;
  border-width: 5px;
  border-color: white;
}
.bgcolor1 .ant-modal-content{
  background-color: #FFE665;
  border-width: 5px;
  border-color: white;
}

.borderTitle {
  color: black;
  background: white;
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 3px transparent;
}